<template>
	<div class="input-wrapper">
		<div v-if="title" class="title">
			{{ title }}
			<span v-if="subtitle">
				<br />
				{{ subtitle }}
			</span>
		</div>
		<div class="input-body" :class="{ 'input-body--error': errors && errors.$error }">
			<MultiSelect
				:value="value"
				:max-height="200"
				:max="max"
				:required="required"
				:options="formList"
				:preserve-search="true"
				:multiple="multiple"
				:close-on-select="!multiple"
				:disabled="disabledIn || disabled"
				:placeholder="placeholder"
				:class="classList"
				track-by="id"
				label="name"
				class="multiselect__check"
				@input="update"
			>
				<template slot="option" slot-scope="{ option }">{{ option.name }}</template>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				<template slot="noResult">{{ $t('components.not_found') }}</template>
				<template slot="noOptions">{{ $t('components.not_found') }}</template>
				<template slot="placeholder">{{ placeholder || $t('placeholder.choose') }}</template>
			</MultiSelect>
			<input
				v-if="!multiple"
				type="text"
				:name="name"
				:required="required"
				:value="value ? value.id : ''"
				class="hidden"
			/>
			<input
				v-else
				type="text"
				:name="name"
				:required="required"
				:value="'[' + idValues(value) + ']'"
				class="hidden"
			/>
		</div>
		<div v-if="errors && !errors.required" class="input-required">
			{{ $t('system_message.required_message') }}
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MultiSelect from 'vue-multiselect'

export default {
	name: 'RegionSelect',
	components: {
		MultiSelect,
	},
	data() {
		return {
			disabledIn: false,
		}
	},
	props: {
		modelValue: {
			type: [Number, Array],
			default: null,
		},
		categoryValue: {
			type: [Number, Array],
			default: null,
		},
		confinesValue: {
			type: Array,
			default: Array,
		},
		errors: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		userBind: {
			type: Array,
			default: Array,
		},
		max: {
			type: Number,
			default: null,
		},
		classList: {
			type: Array,
			default() {
				return []
			},
		},
	},
	emits: ['update:modelValue', 'update:modelObjectsName'],
	watch: {
		categoryValue() {
			this.$emit('update:modelValue', null)
		},
	},
	computed: {
		...mapGetters(['_getRegionList']),
		value() {
			if (this.multiple) {
				if (!this.modelValue?.length) return []

				return this.modelValue?.map((id) => {
					return this._getRegionList.find((e) => e.id === id)
				})
			}

			return this._getRegionList.find((e) => e.id === this.modelValue)
		},
		formList() {
			if (this.allOptions) {
				return [
					{ id: 0, name: 'Все...' },
					...this._getRegionList.filter(
						(r) => !this.confinesValue.length || this.confinesValue.some((r2) => r2.id === r.id),
					),
				]
			}

			return this._getRegionList.filter(
				(r) => !this.confinesValue.length || this.confinesValue.some((r2) => r2.id === r.id),
			)
		},
	},
	beforeMount() {
		this._fetchRegionList()
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		update(value) {
			if (value && !this.multiple) {
				this.$emit('update:modelValue', value.id)
				this.$emit('update:modelObjectsName', value.name)
			} else if (value) {
				this.$emit('update:modelValue', this.idValues(value))
				this.$emit('update:modelObjectsName', this.idNames(value))
			} else {
				this.$emit('update:modelValue', null)
			}
		},
		idNames(array) {
			return array.map((element) => element.name)
		},
		idValues(array) {
			return array.map((element) => {
				return element?.id || element
			})
		},
	},
}
</script>

<style>
.title {
	font-weight: 600;
	font-size: 12px;
	color: #0a091d;
	margin-bottom: 8px;
	line-height: 1.2;
}
.title span {
	color: #708295;
}
.input-body {
	position: relative;
}
.input-body .multiselect__tags {
	padding-left: 20px;
	border: 1px solid #d3e7fb;
	border-radius: 5px;
	background: #ffffff;
	font-weight: 400;
	font-size: 14px;
	color: #0a091d;
	line-height: 1.2;
	width: 100%;
}
.input-body .multiselect--disabled .multiselect__tags {
	border: 1px solid #d3e7fb;
}
.input-body .multiselect--disabled .multiselect__placeholder {
	margin-bottom: 0;
}
.input-body .multiselect__single {
	margin-bottom: 0;
}
.input-body--error.input-body .multiselect__tags {
	border-color: #f3574d;
}
.input-body--error + .input-required {
	display: block;
}

.input-body .input-linck .multiselect__tags {
	border: none;
	padding-left: 4px;
}

.input-body .input-linck {
	padding: 0 !important;
}

.input-body .input-linck .multiselect__single {
	font-size: 12px;
}

.input-body .input-linck .multiselect__placeholder {
	padding-left: 8px;
	font-size: 12px;
	color: #757575;
}
.hidden {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
}
</style>
